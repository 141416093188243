<template>
  <div>
    <loading-content :is-loading="isLoading" />
    <b-card no-body>
      <b-card-header
        class="d-flex align-items-center justify-content-between"
        header-bg-variant="transparent"
      >
        {{ role && `${role.id}. ${role.name}` }}
        <div>
          <b-button class="text-white mr-2" @click="$router.push({ name: 'UsersList' })">
            {{ $t("BACK") }}
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="row">
          <div
            class="col border-right"
            v-for="(headerValue, headerIndex) in headers"
            :key="headerIndex"
            :class="{
              'border-0': headers.length - 1 === headerIndex,
            }"
          >
            <h5 class="font-sm font-weight-bold text-secondary pb-2">
              {{ $t(`ROLE.${headerValue}`) }}
            </h5>
            <span v-if="headerValue === 'confirmed' || headerValue === 'enabled'">
              <b-badge class="text-white" :variant="getUser[headerValue] ? 'success' : 'danger'">
                {{ $t(`TABLE.${headerValue}_${getUser[headerValue].toString().toUpperCase()}`) }}
              </b-badge>
            </span>
            <template v-else>{{ getUser[headerValue] || "Brak" }}</template>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingContent from "@/components/LoadingContent/LoadingContent.vue";
import { GET_USER } from "@/core/store/Users/user.module";

export default {
  name: "Role",
  components: { LoadingContent },
  data() {
    return {
      headers: [
        "id",
        "email",
        "firstName",
        "lastName",
        "enabled",
        "confirmed",
        "createdAt",
        "updatedAt",
      ],
      isLoading: false,
      role: null,
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["getUser"]),
  },
  mounted() {
    this.role = this.$route.params.role;
    if (!this.role) {
      this.isLoading = true;
      this.$store.dispatch(GET_USER, this.$route.params.id).then(() => {
        this.role = this.getUser;
        this.isLoading = false;
      });
    }
  },
};
</script>
